// @import '../import';

body {
  font-family: Causten, sans-serif;
}

.--flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.--center {
  text-align: center;
}

.--clr {
  clear: both;
}

.--pad-margin-0 {
  margin: 0;
  padding: 0;
}
