@import '../../styles/import';

.input {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
  border-bottom: 1px solid $color-gray-charcoal;
  border-radius: 0;
  padding: 4px 0 4px;
  width: 100%;

  &::placeholder {
    color: $color-gray-concreate;
  }

  &:focus {
    outline: none;
  }
}
