/** Reset CSS */

* {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
