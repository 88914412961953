$color-primary: #25c9ef;
$color-dark-blue: #304cb4;
$color-border-blue: #0ea3c6;
$color-light-gray: #f5f5f5;
$color-white: #ffffff;
$color-gray-light: #e0e0e0;
$color-gray-lighter: #c4c4c4;
$color-gray-charcoal: #1a1a1a;
$color-gray-concreate: #85898c;
$color-black: #000000;
$color-soft-red: #fb4f39;
$color-border-grey: rgba(133, 137, 140, 0.5);
$color-soft-red: #fb4f39;
