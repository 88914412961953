@import '../../styles/import';

.toggle-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > * + * {
    margin-left: 6rem;
  }

  &__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: $color-gray-concreate;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: 2px;
    }
  }

  &__btn {
    display: block;
    height: 1.3rem;
    width: 3.4rem;
    background-color: $color-gray-lighter;
    border-radius: 100px;
    position: relative;
    margin: 3px 0 3px;
    cursor: pointer;
  }

  &__thumb {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    height: 1.9rem;
    width: 1.9rem;
    border-radius: 100px;
    background-color: $color-primary;
    transition: all 150ms ease-out;
  }

  &__state {
    font-size: 1.4rem;
    line-height: 2.2rem;
    font-weight: 400;
    font-family: Noto Sans, sans-serif;
    color: $color-gray-charcoal;
  }
}
