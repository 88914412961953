$base-font-size: 1.6rem;

$line-heights: (
  micro: 2rem,
  mini: 2.2rem,
  smallest: 2.4rem,
  medium: 3.2rem,
  big: 4rem,
  bigger: 5.6rem,
  biggest: 6.4rem
);

$font-sizes: (
  micro: 1.2rem,
  mini: 1.4rem,
  smallest: 1.6rem,
  smaller: 2.4rem,
  medium: 3.2rem,
  big: 3.625rem,
  biggest: 4rem,
  bigger: 4.8rem
);

$font-weights: (
  bold: 700,
  semi-bold: 600,
  medium: 500,
  normal: 400,
  light: 300
);

$button-font-size: 1.6rem;

$base-line-height: map-get($line-heights, big);

$heading-fonts: (
  h1: (
    font-size: 5.8rem,
    line-height: 6.4rem
  ),
  h2: (
    font-size: 2.4rem,
    line-height: 3.2rem
  )
);
