@import '../../styles/import';

.form {
  width: 28rem;
  padding: 2.4rem 2.1rem;
  background-color: $color-white;
  border-radius: 1rem;
  box-shadow: 0px 8px 24px rgba(120, 120, 120, 0.5);

  @include respond(mobile) {
    width: 100%;
  }

  &__form {
  }

  &__heading {
    font-size: 2.4rem;
    line-height: 3.2rem;
    font-weight: 500;
    color: $color-gray-charcoal;
    margin-bottom: 2.4rem;
    font-family: $primary-font-family;
  }

  &__controls {
    display: flex;
    flex-direction: column;

    & > * + * {
      margin-top: 3.2rem;
    }
  }

  &__button-container {
    margin-top: 2.4rem;
  }
}
