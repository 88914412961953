@import '../../styles/import';

.drop-down {
  &__label {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    color: $color-gray-concreate;
    margin-bottom: 0.8rem;
  }

  &__selector {
  }
}
