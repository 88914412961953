@import '../../styles/import';

.bars {
  &__element {
    @include flex(flex);
  }
  &__text {
    background-image: url('../../images/background.svg');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2.9rem 4.8rem;
    font-weight: map-get($font-weights, medium);
    font-size: map-get($font-sizes, smaller);
    line-height: map-get($line-heights, medium);
    color: $color-light-gray;
    border-radius: 1rem 0 0 1rem;
    font-family: $primary-font-family;
    width: 42.7rem;
  }
  &__value {
    background: $color-primary;
    opacity: 0.9;
    padding: 1.7rem 4.8rem;
    font-family: $primary-font-family;
    font-weight: map-get($font-weights, medium);
    font-size: map-get($font-sizes, bigger);
    line-height: map-get($line-heights, bigger);
    border-radius: 0 1rem 1rem 0;
    color: $color-white;
    width: 23.3rem;
    @include flex(flex, row, center, center);
  }
}
@include break(map-get($grid-breakpoints, xl)) {
  .bars {
    &__text {
      width: 38rem;
    }
    &__value {
      width: 22rem;
    }
  }
}
@include break(map-get($container-max-widths, xl)) {
  .bars {
    &__value {
      padding: 1.7rem 6.6rem;
    }
  }
}
@include break(map-get($grid-breakpoints, lg-xl)) {
  .bars {
    &__text {
      width: 100%;
    }
    &__value {
      width: 100%;
    }
  }
}
@include break(map-get($grid-breakpoints, sm)) {
  .bars {
    padding: 0 1.6rem;
    &__text {
      padding: 1.2rem 3.6rem;
      font-size: map-get($font-sizes, smallest);
      line-height: map-get($line-heights, smallest);
      @include flex(flex, column, center, center);
    }
    &__value {
      padding: 1.7rem 3.2rem;
      font-size: map-get($font-sizes, medium);
      line-height: map-get($line-heights, big);
      width: 23.3rem;
    }
  }
}
