@import '../../styles/import';

.button {
  height: 4rem;
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 600;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: transform 100ms ease-in;

  &--primary {
    color: $color-white;
    background-color: $color-primary;
  }

  &--primary-disabled {
    color: $color-white;
    background-color: $color-gray-concreate;
    cursor: not-allowed;
  }

  &--secondary {
    color: $color-gray-charcoal;
    background-color: $color-white;
    border: 1px solid $color-gray-charcoal;
  }

  &--secondary-disabled {
    color: $color-gray-charcoal;
    background-color: $color-gray-concreate;
    cursor: not-allowed;
  }

  &--free-size {
    padding: 0.8rem;
    width: 100%;
  }

  &--fixed-size {
    padding: 0.8rem 3rem;
    width: auto;
  }

  // &--disabled {
  //   background-color: $color-gray-concreate;
  // }

  // &--enabled {
  //   &:active {
  //     transform: scale(0.9, 0.95);
  //   }
  // }
}
